import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/invoices', { params: param })
}

const get = (id) => {
  return http.get(`/invoices/${id}`)
}

const getInvoiceStatuses = () => {
  return http.get('/invoice-statuses')
}

const updateInvoice = (data) => {
  return http.post('billings/invoice-items/bulk/update', data)
}

const BillingService = {
  getAll,
  getInvoiceStatuses,
  get,
  updateInvoice,
}

export default BillingService
