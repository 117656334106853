import http from '../utils/http-common'

const getConditions = () => {
  return http.get('/purchase-orders-conditions')
}
const getStatuses = () => {
  return http.get('/purchase-orders-statuses')
}
const getAll = (param) => {
  return http.get(`/purchase-orders`, { params: param })
}
const get = (id) => {
  return http.get(`/purchase-orders/${id}`)
}
const create = (data) => {
  return http.post('/purchase-orders', data)
}
const update = (id, data) => {
  return http.put(`/purchase-orders/${id}`, data)
}
const addNote = (id, data) => {
  return http.post(`/purchase-orders/add/notes/${id}?note=${data}`)
}
// Need refactor from instruction to instructions endpoint
const addInstructions = (id, data) => {
  return http.post(`/purchase-orders/add/instruction/${id}?message=${data}`)
}
const createPurchaseOrderItem = (id, data) => {
  return http.post(`/purchase-order-items/create/${id}`, data)
}
const deletePurchaseOrderItem = (id) => {
  return http.delete(`/purchase-order-items/${id}`)
}
const getPurchaseOrderItem = (id) => {
  return http.get(`/purchase-order-items/${id}`)
}
const updatePurchaseOrderItem = (id, data) => {
  return http.put(`/purchase-order-items/update/${id}`, data)
}
const findByPONumber = (data) => {
  return http.get(`/purchase-orders?list=auto-complete&po_number=${data}`)
}
const importPO = (data) => {
  return http.post(`/purchase-orders-import`, data)
}
// Need refactor from instruction to instructions endpoint
const deletePurchaseOrderInstruction = (id) => {
  return http.post(`/purchase-orders/delete/instruction/${id}`)
}
const purchaseOrderImportingStructure = () => {
  return http.get(`purchase-order-importing-structure`)
}
const getCSVTemplate = () => {
  return http.get(`/import/purchase-order-structure`)
}
const importCSV = (data) => {
  return http.post(`/import/purchase-orders`, data)
}
const exportPurchaseOrders = (param) => {
  return http.get(`/export/purchase-orders`, { params: param })
}
const getPurchaseOrderItemStatuses = () => {
  return http.get('/purchase-orders-item-statuses')
}

const PurchaseOrderDataService = {
  getConditions,
  getStatuses,
  getAll,
  get,
  create,
  update,
  addNote,
  addInstructions,
  createPurchaseOrderItem,
  deletePurchaseOrderItem,
  getPurchaseOrderItem,
  updatePurchaseOrderItem,
  findByPONumber,
  importPO,
  deletePurchaseOrderInstruction,
  purchaseOrderImportingStructure,
  getCSVTemplate,
  importCSV,
  exportPurchaseOrders,
  getPurchaseOrderItemStatuses,
}
export default PurchaseOrderDataService
